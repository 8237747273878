/* wwwroot/css/site.css */
html {
    font-size: 14px;
}

@media (min-width: 768px) {
    html {
        font-size: 16px;
    }
}

html {
    position: relative;
    min-height: 100%;
}

a.navbar-brand {
    white-space: normal;
    text-align: center;
    word-break: break-all;
}

/*a {*/
/*    color: #0077cc;*/
/*}*/

/*.btn-primary {*/
/*    color: #fff;*/
/*    background-color: #1b6ec2;*/
/*    border-color: #1861ac;*/
/*}*/

/*.nav-pills .nav-link.active, .nav-pills .show > .nav-link {*/
/*    color: #fff;*/
/*    background-color: #1b6ec2;*/
/*    border-color: #1861ac;*/
/*}*/

/*.border-top {*/
/*    border-top: 1px solid #e5e5e5;*/
/*}*/
/*.border-bottom {*/
/*    border-bottom: 1px solid #e5e5e5;*/
/*}*/

/*.box-shadow {*/
/*    box-shadow: 0 .25rem .75rem rgba(0, 0, 0, .05);*/
/*}*/

button.accept-policy {
    font-size: 1rem;
    line-height: inherit;
}

.footer {
    width: 100%;
    white-space: nowrap;
}

a {
    display: inline-block;
}